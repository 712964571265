<template>
  <div class="data_trend">
    <el-card class="box-card">
      <p>销售趋势图</p>
      <div style="display: flex; justify-content: flex-end">
        <div class="select" v-if="$store.getters['user/isAllArea']">
          <el-select class="city area_select" placeholder="请选择城市" @change="getData" v-model="areaId">
            <el-option label="全国" :value="-1"></el-option>
            <el-option
              v-for="item in $store.getters['user/userAreaInfoListWithoutGlobal']"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <DateSelect ref="dateSelect" dateType="halfYear" @get-date="handleSetDate" />
      </div>
      <div class="chart" ref="chart"></div>
    </el-card>
    <el-row :gutter="20">
      <el-col :span="12" style="padding: 20px; padding-right: 0">
        <el-card class="box-card">
          <span>订单排行榜</span>
          <div style="float: right">
            <div class="block" style="text-align: right">
              <el-date-picker
                class="month-select"
                v-model="orderDate"
                type="month"
                ref="orderMonth"
                placeholder="选择月"
                value-format="timestamp"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="handleOrderDate"
              >
              </el-date-picker>
              <i class="el-icon-caret-bottom" @click="handleOrderClick"></i>
            </div>
          </div>
          <div class="chart" ref="chart2"></div>
        </el-card>
      </el-col>
      <el-col :span="12" style="padding: 20px">
        <el-card class="box-card">
          <span>销售额排行榜</span>
          <div style="float: right">
            <div class="block" style="text-align: right">
              <el-date-picker
                class="month-select"
                v-model="xiaoDate"
                type="month"
                ref="xiaoMonth"
                placeholder="选择月"
                value-format="timestamp"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="handleXiaoDate"
              >
              </el-date-picker>
              <i class="el-icon-caret-bottom" @click="handleXiaoClick"></i>
            </div>
          </div>
          <div class="chart" ref="chart3"></div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import echarts from 'echarts'
import DateSelect from '@/component/common/dateSelect.vue'

export default {
  data() {
    return {
      areaId: -1,
      value2: [],
      value3: [],
      initData: {},
      orderTenderData: [],
      chartInstance: null,
      orderDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
      orderEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime(),
      xiaoDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime(),
      xiaoEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime(),
      pickerOptions: {
        //控制时间范围
        disabledDate(time) {
          return time.getTime() >= new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime()
        }
      },
      defaultOption: {
        color: this.$store.getters['echarts/colorList'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#fff'
            }
          }
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ]
      },
      orderRankData: [],
      xiaoOrderData: []
    }
  },
  components: {
    DateSelect
  },
  computed: {
    orderChartOption() {
      return {
        ...this.defaultOption,
        xAxis: {
          type: 'category',
          data: this.orderTenderData.map(i => i.localDate)
        },
        yAxis: [
          {
            name: '销售额',
            type: 'value'
          },
          {
            name: '订单',
            position: 'right'
          }
        ],
        legend: {
          data: ['销售额', '订单']
        },
        series: [
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '销售额',
            smooth: true,
            data: this.orderTenderData.map(i => i.orderAmount),
            type: 'line',
            yAxisIndex: 0,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(231,76,60,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(231,76,60,0.1)'
                }
              ])
            }
          },
          {
            itemStyle: {
              normal: {
                label: {
                  show: true
                }
              }
            },
            name: '订单',
            smooth: true,
            data: this.orderTenderData.map(i => i.orderCount),
            type: 'line',
            yAxisIndex: 1,
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(26,188,156,0.5)'
                },
                {
                  offset: 1,
                  color: 'rgba(26,188,156,0.1)'
                }
              ])
            }
          }
        ]
      }
    },
    orderRankOption() {
      const data = this.orderRankData
        .map((c, index) => {
          if (index === 0 || index === 1 || index === 2) {
            c.slotContent = '{imgFormat|}'
          } else {
            c.slotContent = `{text|${index + 1}}`
          }
          switch (index) {
            case 0:
              c.image = require('@/asset/icon/first.png')
              break
            case 1:
              c.image = require('@/asset/icon/sec.png')
              break
            case 2:
              c.image = require('@/asset/icon/third.png')
              break
            default:
              break
          }
          return c
        })
        .reverse()
      return {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let tar = params[0]
            return tar.name.slice(tar.name.lastIndexOf('|') + 1, -1) + '<br/>' + tar.seriesName + ' : ' + tar.value
          },
          axisPointer: {
            type: 'none'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false
          },
          data: data.map(c => {
            return {
              value: `${c.slotContent}{text|${c.areaName}}`,
              textStyle: {
                formatter: '{c.areaName}',
                rich: {
                  text: {
                    padding: 12,
                    fontSize: 14
                  },
                  imgFormat: {
                    backgroundColor: {
                      image: c.image
                    },
                    width: 30,
                    height: 30
                  }
                }
              }
            }
          })
        },
        series: [
          {
            name: '订单数据',
            type: 'bar',
            barWidth: 10,
            barCategoryGap: '10%',
            label: {
              color: '#000',
              show: true,
              position: 'right',
              formatter: function (v) {
                return Math.floor(data[v.dataIndex].orderPercent * 100) / 100 + '%'
              }
            },
            data: data.map(c => c.orderCount)
          }
        ]
      }
    },
    xiaoRankOption() {
      const data = this.xiaoOrderData
        .map((c, index) => {
          if (index === 0 || index === 1 || index === 2) {
            c.slotContent = '{imgFormat|}'
          } else {
            c.slotContent = `{text|${index + 1}}`
          }
          switch (index) {
            case 0:
              c.image = require('@/asset/icon/first.png')
              break
            case 1:
              c.image = require('@/asset/icon/sec.png')
              break
            case 2:
              c.image = require('@/asset/icon/third.png')
              break
            default:
              break
          }
          return c
        })
        .reverse()
      let that = this
      return {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          formatter(params) {
            let tar = params[0]
            return tar.name.slice(tar.name.lastIndexOf('|') + 1, -1) + '<br/>' + tar.seriesName + ' : ' + tar.value
          },
          axisPointer: {
            type: 'none'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: true
          },
          splitLine: {
            show: false
          },
          data: data.map(c => {
            return {
              value: `${c.slotContent}{text|${c.areaName}}`,
              textStyle: {
                formatter: '{c.areaName}',
                rich: {
                  text: {
                    padding: 12,
                    fontSize: 14
                  },
                  imgFormat: {
                    backgroundColor: {
                      image: c.image
                    },
                    width: 30,
                    height: 30
                  }
                }
              }
            }
          })
        },
        series: [
          {
            name: '销售额数据',
            type: 'bar',
            barWidth: 10,
            barCategoryGap: '10%',
            label: {
              color: '#000',
              show: true,
              position: 'right',
              formatter: function (v) {
                return Math.floor(data[v.dataIndex].orderPercent * 100) / 100 + '%'
              }
            },
            data: data.map(c => c.orderAmount)
          }
        ]
      }
    }
  },
  methods: {
    handleOrderClick() {
      this.$refs.orderMonth.$el && this.$refs.orderMonth.$el.children[0].focus()
    },
    handleXiaoClick() {
      this.$refs.xiaoMonth.$el && this.$refs.xiaoMonth.$el.children[0].focus()
    },
    handleOrderDate(val) {
      this.orderEndDate = new Date(new Date(val).getFullYear(), new Date(val).getMonth() + 1, 1).getTime()
      this.loadData2(0, this.orderDate, this.orderEndDate)
    },
    handleXiaoDate(val) {
      this.xiaoEndDate = new Date(new Date(val).getFullYear(), new Date(val).getMonth() + 1, 1).getTime()
      this.loadData2(1, this.xiaoDate, this.xiaoEndDate)
    },
    getData() {
      this.loadData()
      this.loadData2(0, this.orderDate, this.orderEndDate)
      this.loadData2(1, this.xiaoDate, this.xiaoEndDate)
    },
    handleSetDate(v) {
      this.initData = v
      this.loadData()
    },
    loadData() {
      this.$api.statistics
        .statistics('trendList', {
          params: {
            startTime: this.initData.startTime,
            endTime: this.initData.endTime
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          this.orderTenderData = res
          this.$nextTick(() => {
            this.chartInstance.setOption(this.orderChartOption)
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadData2(type, st, et) {
      this.$api.statistics
        .statistics('orderCountRankList', {
          params: {
            orderSearchType: type,
            startTime: st,
            endTime: et
          },
          headers: {
            ['Bm-Area-Id']: this.areaId
          }
        })
        .then(res => {
          if (type === 0) {
            const sortFun = (a, b) => a.orderCount > b.orderCount
            this.orderRankData = res.sort(sortFun)
            console.log('======')
            console.log(this.orderRankData)
            this.$nextTick(() => {
              this.chartInstance2.setOption(this.orderRankOption)
            })
          } else {
            const sortFun = (a, b) => a.orderAmount > b.orderAmount
            this.xiaoOrderData = res.sort(sortFun)
            this.$nextTick(() => {
              this.chartInstance3.setOption(this.xiaoRankOption)
            })
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  created() {
    if (this.$store.getters['user/currentAreaId'] == -1) {
      this.areaId = -1
    } else if (
      this.$store.getters['user/currentAreaId'] == -1 &&
      this.$store.getters['user/userAreaInfoListWithoutGlobal']
    ) {
      this.areaId = this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id
    } else {
      this.areaId = sessionStorage.currentAreaId
    }
    this.loadData2(0, this.orderDate, this.orderEndDate)
    this.loadData2(1, this.xiaoDate, this.xiaoEndDate)
  },
  mounted() {
    this.chartInstance = echarts.init(this.$refs.chart)
    this.chartInstance2 = echarts.init(this.$refs.chart2)
    this.chartInstance3 = echarts.init(this.$refs.chart3)
  }
}
</script>

<style lang="less" scoped>
.data_trend {
  overflow-x: hidden;
}
.chart {
  height: 500px;
}
</style>
<style lang="less">
.area_select {
  width: 70px !important;
  background: #f5f5f5;
  padding-left: 10px;
  border-radius: 20px;
  margin-right: 20px;
  .el-input__inner {
    background: #f5f5f5;
    text-align: left;
    padding: 0;
    border: none;
    border-radius: 20px;
  }
  .el-input__prefix {
    display: none;
  }
}
.block {
  display: inline-block;
  padding: 0 10px;
  background: #f5f5f5;
  border-radius: 30px;
  text-align: right;
}
.month-select {
  width: 50px !important;
  background: #f5f5f5;
  .el-input__inner {
    background: #f5f5f5;
    text-align: right;
    padding: 0;
    border: none;
  }
  .el-input__prefix {
    display: none;
  }
}
</style>
